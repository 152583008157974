
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SelectLanguage from '../../custom/SelectLanguage/SelectLanguage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useI18n from "../../../hooks/useI18n";
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton, Divider } from '@mui/material';
import { forgotPasswordPage } from '../../../constants/pages';
import { appCompno } from '../../../config';

import useLogin from '../../../hooks/useLogin';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();


export default function SignIn() {
  const { l, language } = useI18n();
  const { login, error } = useLogin();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePassword = () => {
    setShowPassword(show => !show);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      login: data.get('login'),
      password: data.get('password'),
      compno: appCompno.toString()
    }
    await login(userData);
  };

  const handleForgotPassword = (event) => {
      event.preventDefault();
      navigate(forgotPasswordPage);
  }
  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >

        <Box
          sx={{
            boxSizing: 'border-box',
            padding: '5rem',
            height: '520px',
            width: '550px',
            boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.3)',
            border: '1px solid #ccc',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            borderRadius: '20px',
             
          }}
        >
          <img src="https://goldencatch.fishing/images/logos/goldencatch-logo.svg" alt="Logo" style={{ width: 400}}/>
          <Divider variant='middle' flexItem sx={{paddingTop: '15px', margin: '0 50px' }} orientation="horizontal" />
          <Typography component="h1" variant="h6" style={{ color:'#F0B21C', paddingTop: '15px', fontFamily: 'sans-serif', textTransform: 'uppercase',  fontWeight: 'bold'}}>
            {l('Order system')}
          </Typography>
          <Box display='flex' flexDirection='column' width="100%" alignItems="center" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label={l("Login")}
              name="login"
              autoComplete="login"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={l("Password")}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={() => handleTogglePassword()} onMouseDown={handleMouseDownPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
              }}
              id="password"
              autoComplete="current-password"
            />

            <Typography color={'red'}>
              {error ? l(error) : ''}
            </Typography>

            <Grid container sx={{justifyContent: 'space-between', alignItems: 'end'}}>
              <Link href="#" variant="body2" onClick={handleForgotPassword}>
                {l('Forgot password') + '?'}
              </Link>
              <SelectLanguage variant={'standard'}/>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size='medium'
              style={{ backgroundColor: '#F0B21C'}}
              sx={{ mt: 5, mb: 3, width: '200px' }}
            >
              {l('Sign In')}
            </Button>
            
          </Box>

          <div className="bottom-hr" id="colorChange"></div>
          <Box 
          sx={{
          width: '100%',
          height: '20px',
          backgroundColor: '#F0B21C',
          position: 'absolute',
          bottom: '0',
          borderRadius: 'inherit',
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0'
          }}/>
        </Box>
      </Box>
    </Container>
  );
}

/*
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from   '../../custom/MDBox';
import MDTypography from "../../custom/MDTypography";
import MDInput from "../../custom/MDInput";
import MDButton from "../../custom/MDButton";

// Authentication layout components
// import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <image={bgImage}>
    <Card>
    <MDBox
      variant="gradient"
      bgColor="info"
      borderRadius="lg"
      coloredShadow="info"
      mx={2}
      mt={-3}
      p={0}
      mb={1}
      textAlign="center"
    >
      <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
        Sign in
      </MDTypography>
    </MDBox>
    <MDBox pt={4} pb={3} px={3}>
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput type="email" label="Email" fullWidth />
        </MDBox>
        <MDBox mb={2}>
          <MDInput type="password" label="Password" fullWidth />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth>
            sign in
          </MDButton>
        </MDBox>
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  </Card>
</>
);
}

export default Basic; */

