import { Routes, Route } from "react-router-dom";
import Login from "./components/pages/Login/Login";
import Main from "./components/pages/Main/Main";
import Orders from "./components/pages/Orders/Orders";
import RequireAuth from './components/hocs/RequireAuth';
import Layout from "./components/layout/Layout/Layout";
import ForgotPassword from "./components/pages/Login/ForgotPassword";
import useLogin from "./hooks/useLogin";
import { useEffect } from "react";
import VerificationPage from "./components/pages/Login/VerificationPage";

import {
	userPage, 
	ordersPage, 
	priceListPage, 
	basketPage, 
	mainPage,
	orderPage, 
	loginPage, 
	forgotPasswordPage, 
	defaultPage, 
	varificationPage,
	userSettingPage,
	passwordChangePage,
} from './constants/pages';
import PriceList from "./components/pages/PriceList/PriceList";
import BasketPage from "./components/pages/Basket/BasketPage";
import UserSetting from "./components/pages/Settings/UserSetting";
import PasswordChange from "./components/pages/Login/PasswordChange";
import Order from "./components/pages/Orders/Order";

function App() {

    const { expiredTokenLogout } = useLogin();

    useEffect(() => {
		document.title = process.env.REACT_APP_NAME || "Golden Catch";
        expiredTokenLogout();
    }, []);

  return (
    <>
        <Routes>
			<Route path={loginPage} element={ <Login /> } />
			<Route path={forgotPasswordPage} element={ <ForgotPassword /> } />
			<Route path={varificationPage} element={ <VerificationPage /> } />
			<Route path={defaultPage}
				element={
					<RequireAuth>
						<Layout />
					</RequireAuth>
				}
			>
				{/* <Route path={mainPage} element={ <Main /> } /> */}
				<Route path={ordersPage} element={ <Orders /> } />
				<Route path={priceListPage} element={ <PriceList /> } />
				<Route path={basketPage} element={ <BasketPage /> } />
				<Route path={userSettingPage} element={ <UserSetting /> } />
				<Route path={passwordChangePage} element={ <PasswordChange /> } />
				{/* <Route path={userPage} element={ <Main /> } /> */}
				<Route path={`${orderPage}/:number`} element={ <Order /> } />
			</Route>
        </Routes>
    </>
  );
}

export default App;
