
import useI18n from "../../../hooks/useI18n";
import * as React from 'react';
import Box from '@mui/material/Box';
import TableData from '../../custom/Table/TableData';
import Typography from '@mui/material/Typography';

import orderService from "../../../api/order";
import useUserInfo from '../../../hooks/useUserInfo';
import CellType from '../../../constants/type';
import { useNavigate } from 'react-router-dom';
import Search from "../../custom/Search/Search";

import { ordersState } from '../../../state/ordersState';
import { useRecoilState } from "recoil";
import { currencySignPrice1 } from '../../../config';


export default function Orders() {
    const { lang, l } = useI18n();
    const [orders, setOrders] = useRecoilState(ordersState);
    const {user} = useUserInfo();
    const navigate = useNavigate();

    const [search, setSearch] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState('');

    const columns = [
        { id: 'OFFICIALSERNR', label: l('Number'), minWidth: 150, type: CellType.STRING },
        { id: 'ORDDATE', label: l('Date'), minWidth: 100, type: CellType.DATEONLY },
        { id: 'SUM4',label: `${l('Sum')},${currencySignPrice1}`, minWidth: 100, type: CellType.ORDERSUM },
        { id: 'COMMENT2', label: l('Comment'), minWidth: 100, type: CellType.STRING },
        { id: 'CUSTOMSTATUSFLAGTEXT', label: l('Status'), minWidth: 100, type: CellType.STRING },
    ];


    const getOrdersList = async () => {
        
        try {
            if (user) {
                const orders = await orderService.findAllByUserId(user.CODE, lang);
                if(orders)
                    setOrders(orders);
            }
            
        } catch (error) {
            console.error('Error getOrdersList :>> ', error);
        }
    }

    React.useEffect(() => {
        if (user) {
            getOrdersList();
        }
    }, [user]);

    function setClickRow(params) {
        navigate(`/order/${params.OFFICIALSERNR}`);
    }

    const handelSearch = (value) => {

        if (value === '') {
            setSearchValue('');
            setSearch([]);
            return;
        }

        setSearchValue(value);

        setSearch(orders?.filter((item) =>
            item.ORDDATE?.toLowerCase().includes(value.toLowerCase())
            || item.OFFICIALSERNR?.toString().toLowerCase().includes(value.toLowerCase())
            || item.CUSTOMSTATUSFLAGTEXT?.toLowerCase().includes(value.toLowerCase())
        ));
    }

    function setTableRows() {

        if (search.length > 0) {
            return search;
        }
      
        return orders;
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" margin={'10px 0'}>
                <Typography component="h2" variant="h5">
                    {l('Orders')}
                </Typography>
                <Search onChange={handelSearch} value={searchValue} />
            </Box>
            <TableData isPointCursor={true} setClickRow={setClickRow} columns={columns} rows={setTableRows()} />
        </>
    );
    

}


